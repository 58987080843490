.NavBar {
    margin-top: 20px;
    margin-right: 100px;
    font-size: 25px;
    
    .links {
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        display: flex;
        justify-content: space-between;
        color: white;
        

        a {
            color: inherit;
            text-decoration: none; 
        } 
        
        a:hover {
            color:#00A0C6; 
            text-decoration:none; 
            cursor:pointer;  
        }

        @media only screen and (max-width: 767px) {
            font-size: 12px;
        }
    }
}

