.header {
    display: flex;
    justify-content: space-between;
    color: white;

    .img {
        height: 300px;
        width: 300px;
        border: solid white 4px;
        border-radius: 100px 50px 60px 20px;
        margin: 40px;
        margin-bottom: 0;

        @media only screen and (max-width: 767px) {
            height: 150px;
            width: 150px;
            border-radius: 50px 25px 30px 10px;
        }
    }

    .img:hover {
        border: solid #00A0C6 4px;
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .name {
            font-size: 130px;
            margin-top: 40px;
            margin-right: 40px;

            @media only screen and (max-width: 767px) {
                font-size: 35px;
            }
        }

        .job {
            font-size: 50px;
            margin-right: 40px;
            color: #00A0C6;

            @media only screen and (max-width: 767px) {
                font-size: 18px;
            }
        }
    }
}
