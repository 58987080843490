.ContactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin: 50px;
    color: white;

    label {
        font-size: 40px;
        padding: 10px;
    }
    
    input {
        height: 30px;
        width: 200px;
        padding: 10px;
    }

    textarea {
        height: 250px;
        width: 300px;
    }
}

.ConfirmationMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
}

p {
    color: white;
    font-size: 30px;
    margin: 30px;
}

button {
        padding: 10px;
        margin: 10px;
        border: none;
        color: #161616;
        background-color: white;
        font-size: 20px;
    }

button:hover {
    cursor: pointer;
}

button:active {
    background-color: #00A0C6;
    color: white;
    box-shadow: none;
}