.intro {
    display: flex;
    justify-content: space-between;
}

.bio {
    text-align: right;
    color: white;
    margin: 0;
    padding: 40px;
    width: 500px;

    @media only screen and (max-width: 767px) {
        font-size: 15px;
        padding: 0px;
        padding-right: 50px;
        padding-top: 20px;
    }
}