.projectGrid {
  display: grid;
  gap: 150px;
  grid-template-columns: 1fr 1fr 1fr;

  @media only screen and (min-width: 768px) and (max-width: 2092px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media only screen and (max-width: 1337px) {
    grid-template-columns: 1fr;
  }
}
