main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.projectContainer {
  margin-top: 70px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
}

footer {
    margin-top: auto;
}

