.projectCard {
    display: flex;
    justify-content: center;
    background-color: #161616;
    border: solid #00A0C6 2px;
    border-radius: 100px 50px 60px 20px;
    height: 320px;
    width: 500px;

    img {
        border-radius: 100px 0px 0px 20px;
        border-right: #00A0C6 solid 2px;

        @media only screen and (max-width: 767px) {
            border-radius: 50px 0px 0px 10px;
        }
    }

    .rightHandSide {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .projectName {
            color: white;
            width: 100%;
            height: 33%;
            padding: 20px;
            font-size: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @media only screen and (max-width: 767px) {
                font-size: 20px;
            }
        }
    
        p {
            color: white;
            width: 100%;
            height: 30%;
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 22px;
            margin: 0;

            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    
        .linkIcons {
            width: 100%;
            display: flex;
            justify-content: space-around;
            color: white;
            height: 20%;
            font-size: 50px;
    
            a { color: inherit; } 
            
            a:hover {
                color:#00A0C6; 
                text-decoration:none; 
                cursor:pointer;  
            }

            @media only screen and (max-width: 767px) {
                font-size: 20px;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        height: 220px;
        width: 300px;
        border-radius: 50px 25px 30px 10px;
        margin-right: 50px;
        margin-left: 50px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

}

.projectCard:hover {
    cursor: default;
}