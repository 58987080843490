.footer {
    background-color: white;
    width: 100%;

    .linkIcons {
        color: #161616;
        font-size: 50px;
        display: flex;
        justify-content: space-around;
        padding: 20px;

        a { color: inherit; } 
        
        a:hover {
            color:#00A0C6;
            text-decoration:none; 
            cursor:pointer;  
        }
    }
}

@media only screen and (max-width: 767px) {
    
  }